& {
    --m3-container-secondary: #1e438f;
    --m3-on-container-secondary: #dae2ff;

    // Same color is used in theme-color tag in html:
    --body-background: #0f0f0f;
    --body-text-color: #cfd5dd;
    --body-light-muted-color: rgba(91, 91, 91, 0.2);
    --body-muted-text-color: #7e7e7e;
    --header-background: #212121cc; // warning: transparency
    --header-logo-color: #eeeeee;
    --header-box-shadow-color: #1010109c;
    --header-border-bottom-color: var(--card-border-color);
    --card-background: #212121;
    --card-border-color: #2f2f2f;
    --card-box-shadow-color: #1010109c;
    --card-header-background: var(--card-background);
    --card-header-color: #bfbfbf;
    --card-header-tab-color: #7c8286;
    --card-row-separator: #2e2e2e;
    --card-pill-background: #333;
    --button-slider-opacity: #7f7f7f;
    --button-slider: #fff;
    --dots-slider: linear-gradient(270deg, rgba(33,33,33,1) 0%, rgba(33,33,33,6) 20%, rgba(33,33,33,0) 100%);
    --blue-bright: #{lighten($blueBright, 15%)};
    --blue-bright-background: rgba(77, 152, 254, 0.1);

    --blue-bright-opacity: rgba(0, 105, 255, 0.2);

    --green-bright: #3fb950;
    --red-bright: #f74b4c;
    --cyan-bright: #80d6ea;
    --yellow-bright: #fdd764;
    --orange-bright: #fda964;
    --badge-orange-background-color: rgba(209, 117, 56, 0.18);
    --badge-orange-color: #d17538;
    --badge-red-color: #f87777;
    --badge-red-background-color: rgba(230, 79, 79, 0.18);
    --badge-green-color: #2bb259;
    --badge-green-background-color: rgba(30, 176, 60, 0.18);
    --badge-blue-color: #4D96FF;
    --badge-blue-background-color: #283546;
    --badge-grey-color: #777;
    --badge-grey-background-color: #2f2f2f;
    --button-widget: #fff;
    --button-widget-hover: #d9d9d9;
    --button-widget-text: #000;
    --button-slider-opacity: #7f7f7f;
    --button-slider: #fff;
    --blink-color: #fff;
    --modal-title-background: #1c1b1b;
    --modal-border-color: var(--card-border-color);
    --modal-banner-background: var(--m3-container-secondary);
    --modal-banner-text-color: var(--m3-on-container-secondary);
    --modal-banner-round-background: #3a5ba9;
    --modal-banner-round-icon-fill: #FFFFFF;

    --filter-item-active: #2575ed;

    --modal-dark-background: #1c1b1b;
    --modal-toggle-background: #0a0a0a;
    --modal-button-select-color: #212121;

    --tx-table-thead-alt-background: #1c1b1b;
    --tx-table-details-background-color: #1a1a1a;
    --tx-table-icon-color: #5c5c5c;
    --tx-table-icon-gray-color: var(--body-muted-text-color);
    --tx-table-icon-error-color: #f87777;
    --tx-table-icon-error-hover-color: #{lighten(#f87777, 10%)};
    --tx-table-icon-error-background: var(--badge-red-background-color);
    --tx-table-icon-in-color: var(--badge-green-color);
    --tx-table-icon-in-background: var(--badge-green-background-color);
    --tx-table-icon-out-color: var(--badge-red-color);
    --tx-table-icon-out-background: var(--badge-red-background-color);
    --tx-messages-message-background: #1c1b1b;

    --table-box-shadow-color: #1010109c;

    --tx-table-hide-button-background: #2c2c2c;

    --indexpage-search-background-color: #000000;
    --ambilight-overlay-color: #303030;
    --nft-preview-image-outline: rgba(255, 255, 255, 0.06);
    --nft-preview-error-card-background: radial-gradient(circle, transparent 30%, rgb(18,18,18) 100%);
    --nft-preview-background: var(--body-light-muted-color);
    --nft-preview-hover-background: #cfd5dd;
    --nft-preview-hover-color: #0e0e0e;
    --big-blue-button-background: #{darken($blueBright, 2%)};
    --big-blue-button-hover-background: #{lighten($blueBright, 3%)};
    --big-blue-button-disabled-background: #474747;
    --big-blue-button-disabled-color: #AAA;
    --button-options-background: #2e2e2e;
    --button-options-background-hover: #383838;
    --button-options-text: #868686;
    --stacked-bar-background: #303030;
    --stacked-bar-meh: #{lighten(#303030, 12%)};
    --stacked-bar-red: linear-gradient(180deg, #A1615E 0%, #834C45 100%);
    --stacked-bar-green: linear-gradient(180deg, #5ea161 0%, #47874e 100%);
    --ui-round-image-color: rgba(91, 91, 91, 0.33);
    --code-viewer-background: #151515;
    --code-viewer-border-color: #2a2a2a;
    --code-viewer-tab-inactive-background: #1c1b1b;
    --code-viewer-file-selector-background-color: rgba(255, 255, 255, 0.06);
    --code-viewer-file-selector-border-color: #353535;
    --user-nft-list-item-background: #2a2a2a;
    --user-nft-list-item-hover-background: #313131;
    --user-nft-list-item-box-shadow-blur: 12px;
    --user-nft-list-item-text-muted-color: #999;
    --page-tx-status-success-color: var(--green-bright);
    --page-tx-status-error-color: #ff706f;
    --page-tx-flow-diagram-border-color: #444444;

    --theme-selector-light-background: #FAFAFA;
    --theme-selector-light-border-color: var(--card-background);
    --theme-selector-light-separator-color: #ddd;
    --theme-selector-light-text-color: #24292f;

    --theme-selector-dark-background: var(--card-row-separator);
    --theme-selector-dark-border-color: #404040;
    --theme-selector-dark-separator-color: #404040;
    --theme-selector-dark-text-color: var(--body-text-color);

    // auto color is the same with current system color (DARK):
    --theme-selector-auto-background: var(--theme-selector-dark-background);
    --theme-selector-auto-border-color: var(--theme-selector-dark-border-color);
    --theme-selector-auto-separator-color: var(--theme-selector-dark-separator-color);
    --theme-selector-auto-text-color: var(--theme-selector-dark-text-color);

    --chart-grid-color: #2a2a2a;
    --chart-pie-border-color: var(--card-background);
    --chart-skeleton-background-color: var(--body-light-muted-color);
    --chart-skeleton-color: #FFFFFF19;
    --chart-line-green-color: #6c8e75;

    --chart-line-green-top-color: rgba(108, 142, 117, 0.56);
    --chart-line-red-bottom-color: #EF535047;
    --chart-line-red-bottom-secondary-color: transparent;

    --chart-line-green-opacity-color: #6C8E7599;
    --chart-line-green-secondary-color: #21212100;
    --chart-line-red-color: #bd4747;
    --chart-line-muted-color: #FFFFFF26;
    --chart-line-green-opacity-mobile-color: #B9B9B933;

    --chart-line-mini-color: #40b950;
    --chart-line-mini-opacity-color: rgb(64, 185, 80, 0.6);
    --chart-line-mini-green-secondary-color: rgba(33,33,33,0);

    --chart-line-mini-red-color: #f74c4c;
    --chart-line-mini-opacity-red-color: rgb(247, 76, 76, 0.6);
    --chart-line-mini-red-secondary-color: rgba(33,33,33,0);

    --chart-interval-selector-item-background: var(--card-row-separator);
    --chart-interval-selector-item-active-color: var(--body-text-color);
    --chart-interval-selector-background: rgba(0, 0, 0, 0.4);
    --chart-interval-selector-color: #cfd5dd90;
    --chart-bar-primary-color: var(--chart-line-green-color);
    --chart-bar-secondary-color: #6c8e7550;
    --chart-tooltip-background: #2e2e2e;
    --chart-tooltip-color: var(--body-text-color);
    --chart-point-color: #666;

    // blocks table
    --table-xheader-background: var(--card-row-separator);
    --table-header-color: #cfd5dd90;
    --table-row-border: 1px solid var(--card-row-separator);

    --widget-stakee-header-color: var(--body-text-color);
    --widget-view-all-btn-hover: #{lighten($blueBright, 22%)};

    --config-param-number-text-color: var(--body-text-color);
    --config-param-number-border-color: #525252;
    --config-param-number-background-color: transparent;
    --config-param-point-background-color: #2d2d2d;
    --config-param-point-text-color: #5e5e5e;
    --config-param-no-value: var(--code-viewer-background);

    // Skeleton loader
    --skeleton-loader-background: rgba(255, 255, 255, 0.06);

    // App section
    --app-short-description: #929497;
    --app-card-description: var(--app-short-description);
    --app-info-name: var(--app-short-description);
    --app-info-value: #0065F5;
    --app-icon-background: #2e2e2e;
    --app-card-badge-new-background: #2d453b;
    --app-logo-shadow: 1px 1px rgba(0, 0, 0, 0.5);
    --app-search-field-focused-border-color: #2575ed;
    --app-search-badge-text-color: #bbbfc4;
    --app-icon-verified-color: #4d96ff;

    // Selector component
    --selector-active: #2f2f2f;
    --selector-background: var(--card-background);
    --selected-big-text: #909090;
    //--selector-big-selected-text: var(--body-text-color);
    --selector-big-selected-text: var(--body-text-color);
    --selector-big-selected-border: var(--app-search-field-focused-border-color);
    --selector-big-selected-background: transparent;
    --selector-big-mobile-border-color: transparent;

    --selector-small-background: var(--card-background);
    --selector-small-text: #909090;
    --selector-small-selected-background: #333333;
    --selector-small-selected-text: var(--body-text-color);

    // Apps list
    --app-list-nav-btn-open-background: var(--card-background);
    --app-list-nav-btn-text: #909090;

    // Apps navigation
    //--apps-navigation-icon-active: #3D73E5;
    --apps-navigation-icon-active: var(--blue-bright);
    --apps-navigation-category-active-color: var(--body-text-color);
    --apps-navigation-inactive-color: #858585;
    --apps-navigation-hover-color: var(--body-text-color);

    // Other
    --icon-color: var(--body-text-color);
    --icon-hover-color: var(--body-text-color);

    // Mobile navigation
    --apps-navigation-btn-background: var(--card-background);
    --apps-navigation-btn-border-color: transparent;
    --apps-navigation-btn-selected-background: rgba(255,255,255, 0.1);
    --apps-navigation-btn-selected-text-color: var(--body-text-color);
    --apps-navigation-btn-selected-border-color: var(--app-search-field-focused-border-color);
    --apps-navigation-btn-active-background-color: rgba(255,255,255, 0.1);

    // Breadcrumbs
    --breadcrumbs-text-color: #878b8f;
    --breadcrumbs-selected-text-color: var(--body-text-color);

    --theme-popup-background: var(--tx-table-thead-alt-background);
    --theme-popup-active-background: var(--button-options-background);

    // Diagram
    // --diagram-circle-color: #fff;
    --diagram-circle-color: #2f5386;
    --diagram-circle-faded-color: #20324e;
    --diagram-circle-red-color: #833b3b;
    --diagram-line-color: #626262;
    --diagram-scroll: #fff;
    --diagram-scroll-background: #1f1f1f;
    --diagram-line-color-hover: #262f3d;
    --diagram-line-select-color: var(--body-background);

    --success-status-transaction-background: #063D2E;
    --success-status-transaction-border: #38bd88;

    --fail-status-transaction-background: var(--badge-red-background-color);
    --fail-status-transaction-border: var(--badge-red-color);

    --success-skeleton-transaction-background: #212121;
    --success-skeleton-transaction-border: #2f2f2f;

    --hidden-json-expander: #1c1b1b;

    // Tags on address page
    --address-tag-green-color: var(--tx-table-icon-in-color);
    --address-tag-green-background: var(--tx-table-icon-in-background);
    --address-tag-blue-color: #4D96FF;
    --address-tag-blue-background: #283546;
    --address-tag-orange-color: #fda964;
    --address-tag-orange-background: #463628;
    --address-tag-red-color: var(--badge-red-color);
    --address-tag-red-background: #462828;
    --address-tag-red-border: #462828;
    --address-tag-gray-color: #cfd5dd;
    --address-tag-gray-background: #3a3a3a;

    // Advertise page
    --advertise-page-screen-gradient: linear-gradient(to right, #1E8DFF, #125599);
    --advertise-page-block-bg: var(--body-background);

    // Spintria ad
    --spintria-btn-bg: linear-gradient(to bottom, #d8d6ff, #b3b0ff);
    --spintria-btn-bg-hover: linear-gradient(to bottom, #e5e4ff, #c0beff);
    --spintria-btn-color: #5d55f6;

    --chapter-active-background: var(--address-tag-blue-background);

    // Neuton ad
    --neuton-text-color: white;
    --neuton-description-color: #A3A6BF;

    --neuton-mobile-background: linear-gradient(to right, #004A74, var(--card-background) 70%);
    --neuton-mobile-btn-background: #05A5FF;
    --neuton-mobile-btn-hover-background: #27affb;

    --dedust-text-color: #000;
    --dedust-bg-color: #F5F5F5;
}
